.profile-img {
  img {
    @if $enable-rounded ==true {
      @include radius-mixin($border-radius-pill);
    }

    border: 1px solid rgba(0, 0, 0, 0.1);
    height: auto;
  }
}

.header-avatar {
  .profile {
    background-color: var(--#{$prefix}gray-900);
  }

  .chat-status {
    padding: 0.297rem 1rem;
    letter-spacing: 1px;
    font-weight: 400;
    position: absolute;
    bottom: -0.5rem;
    left: 50%;
    transform: translateX(-50%);
    line-height: 1.125rem;
  }
}

ul.header-nav {
  position: absolute;
  left: auto;
  right: 0;
  top: auto;
  bottom: 1.25rem;

  li {
    list-style: none;

    a {
      display: block;
      list-style: none;
      height: 2.5rem;
      width: 2.5rem;
      background: $white;

      @if $enable-rounded ==true {
        @include radius-mixin($border-radius-pill);
      }

      line-height: 2.5rem;
      text-align: center;
      color: var(--bs-primary);
      font-size: 1.125rem;
      margin: 0 0.938rem 0 0;
    }
  }
}

.profile-header {
  .user-detail {
    position: absolute;
    left: 0;
    right: 0;
    top: auto;
    bottom: 0rem;
  }
}

.profile-header.profile-info {
  .user-detail {
    bottom: 1.25rem;
  }
}

.profile-feed-items {
  li {
    list-style: none;

    &:last-child {
      a {
        margin: 0;
      }
    }

    a.nav-link {
      text-decoration: none;
      text-transform: capitalize;
      color: var(--bs-secondary);
      text-align: center;
      padding: 1rem 0.625rem;
    }
  }
}

.nav-pills.profile-feed-items {
  li {
    a.active {
      color: $white;
      background: var(--bs-primary);
    }
  }
}

.news-icon {
  font-size: 1.125rem;
  margin-right: 1.25rem;
}

.job-icon-position {
  text-align: center;
  margin: -1.25rem auto 0;
}

.event-post {
  .job-icon {
    height: 2.5rem;
    width: 2.5rem;
    line-height: 1.5rem;
    text-align: center;
    font-size: 1.125rem;
  }
}

ul.media-story {
  li {
    > img {
      height: 3.75rem;
      width: 3.75rem;
      line-height: 3.75rem;
      text-align: center;
      border: 2px solid rgba(0, 0, 0, 0.1);
      padding: 0.125em;

      @if $enable-rounded ==true {
        @include radius-mixin($border-radius-pill);
      }
    }

    > i {
      height: 3.75rem;
      width: 3.75rem;
      line-height: 3.438rem;
      text-align: center;
      border: 2px solid var(--#{$prefix}border-color);
      padding: 0.125em;

      @if $enable-rounded ==true {
        @include radius-mixin($border-radius-pill);
      }

      font-size: 1.25rem;
    }
  }

  li.active {
    img {
      border-color: var(--bs-primary);
    }
  }
}

.add-suggestion {
  border: 1px solid var(--bs-primary);
  height: 2.5rem;
  width: 2.5rem;

  @if $enable-rounded ==true {
    @include radius-mixin($border-radius-pill);
  }

  text-align: center;
  line-height: 2.188rem;
  font-size: 1rem;
  color: var(--bs-primary);
}

.suggestions-lists {
  .btn {
    display: block;
  }
}

.feather-icon {
  a {
    color: $secondary;
    font-weight: 300;

    &:hover {
      color: var(--bs-primary);
    }
  }
}

.message-icon {
  a {
    color: $secondary;
    font-weight: 300;

    &:hover {
      color: var(--bs-primary);
    }
  }
}

ul.post-comments {
  list-style: none;
}

.comment-activity {
  > a {
    margin-right: 0.625rem;
    text-transform: capitalize;
    text-decoration: none;
  }
}

form.comment-text {
  position: relative;

  input {
    padding-right: 8.125rem;
  }
}

.comment-attagement {
  position: absolute;
  left: auto;
  right: 0;
  font-size: 1.25rem;

  a {
    color: var(--bs-body-color);

    &.link {
      transform: rotate(130deg);
    }
  }
}

#profile-activity {
  .iq-timeline {
    li {
      margin-bottom: 0.938rem;
    }
  }
}

.about-info {
  a {
    color: $body-color;
  }
}

div#profile-profile {
  a {
    color: $body-color;
  }
}

.twit-date {
  a {
    color: $body-color;
  }
}

.friend-list-items.nav-pills {
  .nav-link.active {
    background-color: transparent;
    color: $black;
  }

  .show {
    > .nav-link {
      background-color: transparent;
      color: $black;
    }
  }
}

.iq-friendlist-block {
  border: 1px solid rgba($black, 0.2);
}

.image-hover-data {
  position: absolute;
  left: 0;
  right: 0;
  top: auto;
  bottom: -3.75rem;
  padding: 0.938rem;

  @if $enable-rounded ==true {
    @include radius-mixin(0 0 5px 5px);
  }

  background: rgba($black, 0.5);

  @if $enable-transitions ==true {
    @include transition-mixin(all 0.45s ease-in-out);
  }
}

.user-images {
  &:hover {
    .image-hover-data {
      bottom: 0;

      @if $enable-transitions ==true {
        @include transition-mixin(all 0.45s ease-in-out);
      }
    }

    a.image-edit-btn {
      opacity: 1;

      @if $enable-transitions ==true {
        @include transition-mixin(all 0.45s ease-in-out);
      }
    }
  }
}

a.image-edit-btn {
  position: absolute;
  top: 0.938rem;
  left: auto;
  right: 0.938rem;
  background: $white;
  height: 1.563rem;
  width: 1.563rem;
  text-align: center;
  line-height: 1.563rem;

  @if $enable-rounded ==true {
    @include radius-mixin($border-radius);
  }

  opacity: 0;
}

.event-images {
  img {
    @if $enable-rounded ==true {
      @include radius-mixin(5px 5px 0 0);
    }
  }
}

.iq-notify {
  width: 1.563rem;
  height: 1.563rem;
  line-height: 2.2rem;
  text-align: center;
}

.files-lists.table {
  td {
    vertical-align: middle;
  }
}

.inner-page-title {
  position: absolute;
  top: 25%;
  left: 1.875rem;
}

.inner-page-bg {
  img {
    height: 150px;
  }
}

ul.request-list {
  > li {
    margin-bottom: 0.938rem;
    padding-bottom: 0.938rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      border: none;
    }
  }
}

.post-item {
  img.profile-img {
    @if $enable-rounded ==true {
      @include radius-mixin(100%);
    }

    height: 28.125rem;
    width: 28.125rem;
  }
}

.user-post {
  img.profile-img {
    @if $enable-rounded ==true {
      @include radius-mixin(100%);
    }

    height: 25rem;
    width: 25rem;
  }
}

.iq-card-post-toolbar {
  .dropdown-toggle {
    i {
      font-size: 1.375rem;
      line-height: normal;
      color: $body-color;
    }

    &::after {
      display: none;
    }
  }

  .dropdown-menu {
    a {
      border-bottom: 1px solid rgba($black, 0.1);
    }
  }
}

.total-comment-block {
  .dropdown-toggle {
    &::after {
      display: none;
    }
  }
}

.total-like-block {
  .dropdown-toggle {
    &::after {
      display: none;
    }
  }
}

.like-data {
  .dropdown-toggle {
    &::after {
      display: none;
    }
  }
}

.event-post {
  .job-icon {
    height: 2.5rem;
    width: 2.5rem;
    line-height: 1.5rem;
    text-align: center;
    font-size: 1.125rem;
  }
}

.job-icon-position {
  text-align: center;
  margin: -1.25rem auto 0;
}

.play-btn {
  position: absolute;
  left: 0;
  right: 0;
  top: 35%;
  background: rgba(0, 0, 0, 0.8);
  height: 2.188rem;
  width: 2.188rem;
  text-align: center;
  line-height: 2.5rem;
  margin: 0 auto;
  color: $white;

  @if $enable-rounded ==true {
    @include radius-mixin($border-radius-pill);
  }

  font-size: 1.563rem;
  opacity: 0;

  @if $enable-transitions ==true {
    @include transition-mixin(all 0.45s ease-in-out);
  }
}

.music-thumbnail {
  &:hover {
    .play-btn {
      opacity: 1;

      @if $enable-transitions ==true {
        @include transition-mixin(all 0.45s ease-in-out);
      }
    }
  }
}

.modal-open {
  overflow: scroll;
}

/*-----------------profile status avatar----------*/
.iq-profile-avatar {
  position: relative;

  &:before {
    content: "";
    position: absolute;

    @if $enable-rounded ==true {
      @include radius-mixin(100%);
    }

    z-index: 1;
    border: 2px solid $white;
    background-color: $body-color;
    height: 1rem;
    width: 1rem;
    right: 0;
    bottom: -0.188rem;
  }
}

.iq-profile-avatar.status-online {
  &:before {
    background-color: $success;
  }
}

.iq-profile-avatar.status-away {
  &:before {
    background-color: $warning;
  }
}

.cover-container {
  position: relative;
}

.image-offer-btn {
  position: absolute;
  top: 2rem;
  right: 2rem;
}

/*======================
post-user-liked-list
==========================*/
.post-user-liked-list {
  li {
    display: inline-block;
    margin-left: -13px;

    &:first-child {
      margin-left: 0;
    }

    .userimg {
      border: 2px solid var(--bs-border-color);
      height: 24px;
      width: 24px;
      object-fit: cover;
    }
  }
}

.profile-status-online {
  position: relative;

  &::before {
    content: "";
    height: 0.375em;
    width: 0.375em;
    background: var(--bs-success);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    border-radius: 50%;
    margin-right: -1rem;
  }
}

.profile-status-offline {
  position: relative;

  &::before {
    content: "";
    height: 0.375em;
    width: 0.375em;
    background: var(--bs-warning);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    border-radius: 50%;
    margin-right: -1rem;
  }
}

.post-overlay-box {
  position: relative;
  overflow: hidden;

  a[data-fslightbox="gallery"] {
    color: var(--bs-white);
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    transform: translate(-50%, -50%);
  }

  &::after {
    content: "";
    height: 100%;
    width: 100%;
    background-color: var(--bs-dark);
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.header-cover-image {
  height: 25rem;
}

.comment-list {
  .comment-list-block {
    overflow-x: hidden;

    .comment-list-user-comment {
      margin-left: 4rem;
    }
    .comment-list-comment {
      display: inline-block;
      background-color: var(--bs-gray-100);
      padding: 0.75rem 1rem;
      border-radius: var(--bs-border-radius-lg);
    }
  }
}

@include media-breakpoint-down(lg) {
  .profile-forum-items {
    width: 100% !important;
  }

  .user-detail {
    margin-bottom: 0.938rem;

    .d-flex {
      display: block !important;
      text-align: center;
    }
  }

  .profile-img {
    text-align: center;
    padding-right: 0 !important;
  }

  .profile-feed-items {
    width: 100%;
    justify-content: center;
  }

  .user-list-files.d-flex.float-right {
    display: block !important;
    text-align: center;
    margin-top: 1.875rem;
    width: 100%;
  }

  ul.post-opt-block {
    grid-auto-flow: row;
  }

  .profile-header {
    .user-detail {
      position: relative;
      bottom: 0;
      margin-top: -3.125rem;
    }
  }

  .profile-info.d-flex {
    display: block !important;
  }

  .social-info {
    margin-top: 1.25rem;
  }
}

@include media-breakpoint-down(md) {
  ul.friend-list-items {
    display: block !important;
    text-align: center;
  }

  .header-for-bg {
    .title-on-header {
      top: 30%;
    }

    .data-block {
      padding: 0.5rem;

      h2 {
        font-size: 16px;
      }
    }
  }

  .members-list {
    border-bottom: none !important;
  }
}

@include media-breakpoint-down(sm) {
  ul.header-nav {
    li {
      height: 2.188rem;
      width: 2.188rem;
      line-height: 2.188rem;
      margin: 0 0.625rem 0 0;
      font-size: 1rem;
    }
  }

  .iq-friendlist-block {
    .d-flex {
      display: block !important;
      text-align: center;
      margin: 0.625rem;
    }
  }

  .post-item {
    img.profile-img {
      height: 18.75rem;
      width: 18.75rem;
    }
  }

  .user-post {
    img.profile-img {
      height: 18.75rem;
      width: 18.75rem;
    }
  }
}

// profile new page
.profile-page {
  .main-content {
    .content-inner {
      padding: 0;
    }
  }
}
.header-cover-img {
  height: 25rem;
}
.profile-box {
  margin-top: -8rem;
  position: relative;
}
.profile-center {
  margin-top: -9rem;
  .header-avatar {
    margin-bottom: 2.188rem;
    .chat-status {
      font-size: 0.8rem;
      padding: 0 1rem 0.297rem;
      position: absolute;
      bottom: -0.5rem;
      left: 50%;
      transform: translateX(-50%);
    }
    .change-profile-image {
      position: absolute;
      left: auto;
      right: -0.5rem;
      top: -0.8rem;
      height: 2rem;
      width: 2rem;
      line-height: 2.2rem;
    }
  }
}
.user-meta {
  li {
    display: block;
    text-align: center;
    width: 33.33%;
    padding: 0 1em;
    position: relative;
    font-size: 0.875rem;
    font-weight: $font-weight-medium;
    letter-spacing: 1px;
    > h5 {
      margin-bottom: 0.25em;
      font-weight: $font-weight-semibold;
    }
    &::after {
      position: absolute;
      content: "";
      top: 50%;
      bottom: 0;
      left: auto;
      right: 0;
      background: var(--bs-border-color);
      height: 0.875em;
      width: 0.125em;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
    }
    &:last-child {
      &::after {
        content: none;
      }
    }
  }
}

@media (max-width: 991px) {
  .header-cover-img {
    height: 18rem;
  }
  .profile-center {
    .header-avatar {
      margin-bottom: 1.188em;
    }
  }
  .item-header-content {
    .profile-left {
      -webkit-box-ordinal-group: 4;
      -ms-flex-order: 3;
      order: 3;
      text-align: center;
    }
    .profile-center {
      -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
      order: 1;
    }
    .profile-right {
      -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
      order: 2;
      margin: 2em 0;
      text-align: center;
    }
  }
}

@media (max-width: 767px) {
  .header-cover-img {
    height: 16rem;
  }
  .profile-center {
    margin-top: -6rem;
  }
  .user-meta {
    li {
      padding: 0 0.3125rem;
    }
  }
}

// user tab
.item-list-tabs {
  .nav-pills.profile-feed-items {
    li {
      a {
        position: relative;
        color: var(--bs-body-color);
        background: transparent;
        .icon {
          background: var(--bs-primary-bg-subtle);
          color: var(--bs-body-color);
          height: 2.2em;
          width: 2.2em;
          line-height: 2.5em;
          font-size: 1.5em;
        }
        &.active {
          color: var(--bs-primary);
          .icon {
            background: var(--bs-primary);
            color: var(--bs-white);
          }
        }
      }
    }
  }
  .profile-feed-items {
    li {
      padding: 0.5em 0.5em;
      display: inline-block;
      margin: 0 auto;
      text-align: center;
      position: relative;
      &::after {
        position: absolute;
        content: "";
        top: 0;
        bottom: 0;
        left: auto;
        right: 0;
        background: var(--bs-border-color);
        height: 100%;
        width: 0.0625em;
      }
    }
  }
}

.social-user-meta-list {
  li {
    position: relative;
    flex-grow: 1;
    padding: 0 1em;
    width: 110px;
    min-width: 33.33%;

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      right: 0;
      background: var(--bs-border-color);
      height: 14px;
      width: 2px;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
    }

    &:last-child {
      &::after {
        content: none;
      }
    }
  }
}

@media (max-width: 767px) {
  .item-list-tabs {
    .profile-feed-items {
      li {
        padding: 0;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .comment-list {
    .comment-list-block {
      .comment-list-user-comment {
        margin-left: 3.5rem;
      }
    }
  }

  .social-user-meta-list {
    li {
      padding: 0 0.5em;
    }
  }
}
